@import url("https://fonts.googleapis.com/css?family=Signika:300,400,600,700&display=swap");

$gray-100: #efefef;
$gray-200: #e9e9e9;
$gray-300: #e4e4e4;
$gray-400: #dadada;
$gray-500: #bdbdbd;
$gray-600: #8a8a8a;
$gray-700: #505050;
$gray-800: #383838;
$gray-900: #272727;

$white: #fff;
$dark-blue: #0256a4;
$blue: #0358a3;
$light: $white;
$dark: #003366;
$link-color: $dark-blue;
$primary: $blue;
$black-blue: #00182e;
$deep-blue: #002341;
$red: #ec3827;
$cyan: #0078e2;

$theme-colors: (
  "black-blue": $black-blue,
  "deep-blue": $deep-blue,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
);

$font-family-sans-serif: Signika, "Tahoma", "Helvetica Neue", Arial, "Noto Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";

@import "bootstrap/bootstrap";
// @import "fontawesome/fontawesome";
// @import "fontawesome/solid";
// @import "fontawesome/brands";

body {
  padding: 0 0 0 0;
  min-height: 100vh;
  position: relative;
  font-weight: 300;
}

svg {
  width: 100%;
}
